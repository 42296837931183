export function buttonActionFunc() {
	let pageNateItemCount = $('ul.custom li.page').length;
	let pageNateItem = $('ul.custom li.page');
	if(pageNateItemCount > 5){
	  let pageNateNum = $('ul.custom li.page.active').find('a').text() - 1;
	  let pageNateAllNum = pageNateItemCount - 1;
  
	  console.log(pageNateNum);
	  console.log(pageNateAllNum);
	  
	  if(pageNateNum == 0){
		  pageNateItem.slice(5, pageNateItemCount).css('display', 'none');
	  } else if(pageNateNum == 1){
		  pageNateItem.slice(5, pageNateItemCount).css('display', 'none');
	  } else if(pageNateNum == 2){
		  pageNateItem.slice(0, pageNateNum - 2).css('display', 'none');
		  pageNateItem.slice(pageNateNum + 3, pageNateItemCount).css('display', 'none');
	  }  else if(pageNateNum == 3){
		pageNateItem.slice(0, pageNateNum - 2).css('display', 'none');
		pageNateItem.slice(pageNateNum + 3, pageNateItemCount).css('display', 'none');
	  } else if(pageNateNum == pageNateAllNum){
		  pageNateItem.slice(0, pageNateNum - 4).css('display', 'none');
	  } else if(pageNateNum == pageNateAllNum - 1){
		 pageNateItem.slice(0, pageNateNum - 3).css('display', 'none');
		 pageNateItem.slice(pageNateNum + 2, pageNateItemCount).css('display', 'none');
	  }  else if(pageNateNum == pageNateAllNum - 2){
		pageNateItem.slice(0, pageNateNum - 3).css('display', 'none');
		pageNateItem.slice(pageNateNum + 2, pageNateItemCount).css('display', 'none');
	 } else {
		 pageNateItem.slice(0, pageNateNum - 2).css('display', 'none');
		 pageNateItem.slice(pageNateNum + 3, pageNateItemCount).css('display', 'none');
	  }
	}
  
	//戻るボタン
	var ans; //1つ前のページが同一ドメインかどうか
	var bs  = false; //unloadイベントが発生したかどうか
	var ref = document.referrer;
	$(window).bind("unload beforeunload",function(){
		bs = true;
	});
	var re = new RegExp(location.hostname,"i");
	if(ref.match(re)){
		ans = true;
	}else{
		ans = false;
	}
  
	var regexp1 = new RegExp("/news/");
	//パラメーターの取得
	var param = location.search;
  
	$('#newsToListBtn a').bind("click",function(){
	  var that = this;
	  if(ans){
  
		if(param == "?cat=news"){
		  location.href = $(that).attr("href");
		} else if(ref.match(regexp1)){
		  history.back();
		}
  
		setTimeout(function(){
		  if(!bs){
			location.href = $(that).attr("href");
		  }
		},100);
	  }else{
		location.href = $(this).attr("href");
	  }
	  return false;
	});
}

