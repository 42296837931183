//トップページ　キービジュアルスライダー
import 'slick-carousel';
export function idxFunc() {
  let indexTop = $("#indexTop").length

  if(indexTop){

    $("#mainVisualSlide").slick({
      autoplay:true,
      arrows:false,
      speed:1500,
      autoplaySpeed:3000,
      infinite: true,
      fade:true,
    });
  }
}